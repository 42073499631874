<template>
  <v-dialog v-model="modalData.dialog" max-width="950px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="handleNew"
      >
        New Website
      </v-btn>
    </template>

    <v-card>
      <v-form v-model="formValid" ref="form">
        <v-card-title>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <span class="headline">{{ formTitle }} </span>
              <v-btn
                @click="handleCloseModalForm"
                style="float: right; cursor: pointer"
                icon
                color="#232341"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row style="margin: 0">
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3" md="3">
              <v-select
                v-if="statuses.system"
                v-model="formModel.status"
                :items="statuses.companySettings.site"
                label="Status"
                item-text="value"
                item-value="key"
                :id="dynamicID"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-container>
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  v-model="formModel.name"
                  label="Domain"
                  :rules="nameRules"
                  :id="dynamicID"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  v-model="formModel.url"
                  label="URL"
                  :rules="nameRules"
                  :id="dynamicID"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  v-model="formModel.ip_address"
                  label="IP address"
                  :id="dynamicID"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="formModel.price_list_id"
                  :items="priceListCollection"
                  label="Select price list"
                  item-text="name"
                  item-value="id"
                  :rules="countryRules"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="formModel.special_price_list_id"
                  :items="priceListCollection"
                  label="Select special price list"
                  item-text="name"
                  item-value="id"
                  clearable
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="formModel.sales_point_id"
                  :items="salesPointCollection"
                  label="Select sales point"
                  item-text="name"
                  item-value="id"
                  :rules="countryRules"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <!-- <v-autocomplete
                  v-model="formModel.custom_fields.selected_products"
                  :items="itemCollection"
                  item-text="ReferenceName"
                  item-value="id"
                  label="Search item"
                  :rules="requiredRules"
                  multiple
                  deletable-chips
                  small-chips
                >
                </v-autocomplete> -->
                <!-- deletable-chips -->

                <v-autocomplete
                  v-model="formModel.custom_fields.selected_products"
                  :items="itemCollection"
                  label="Search item"
                  :rules="requiredRules"
                  multiple
                  small-chips
                  item-text="ReferenceName"
                  item-value="id"
                >
                  <template #selection="data">
                    <draggable
                      :id="data.index"
                      :list="formModel.custom_fields.selected_products"
                      v-bind="dragOptionsChips"
                      :move="move"
                      @change="change"
                    >
                      <v-chip
                        draggable
                        v-model="
                          formModel.custom_fields.selected_products[data.index]
                        "
                        :key="data.item.id"
                        @mousedown.stop
                        @click.stop
                      >
                        {{ data.item.ReferenceName }}
                      </v-chip>
                    </draggable>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  v-if="formModel"
                  v-model="formModel.custom_fields.web_category_id"
                  :items="webCategoryCollection"
                  item-text="name"
                  item-value="id"
                  label="Select root web category"
                  :id="dynamicID"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="formModel.custom_fields.web_content_version"
                  :items="[1, 2]"
                  label="Select webcontent version"
                  :rules="requiredRules"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCloseModalForm">
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="handleSaveModalForm"
            v-if="
              (permissionCan('create') && this.modalData.editedIndex === -1) ||
              permissionCan('update')
            "
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
      <v-overlay :value="loader">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import draggable from "vuedraggable";
// import i18nService from "@/core/services/i18n.service.js";

const TRANSLATED_ATRIBUTES = [];

export const initialFormData = {
  id: "",
  status: 1,
  name: "",
  url: "",
  sales_point_id: null,
  price_list_id: null,
  special_price_list_id: null,

  custom_fields: {
    selected_products: [],
    web_content_version: 1,
    web_category_id: null,
  },
};

export default {
  name: "WebsiteModalForm",
  props: ["modalData", "statuses", "permissions", "loader"],
  components: { draggable },
  data() {
    return {
      formModel: initialFormData,
      formValid: false,
      nameRules: [
        (v) => !!v || "This field is required",
        (v) => v.length > 2 || "This field must be min 3 characters",
      ],
      countryRules: [(v) => !!v || "This field is required"],

      dragged: {
        from: -1,
        to: -1,
        newIndex: -1,
      },
    };
  },
  computed: {
    ...mapGetters([
      "priceListCollection",
      "salesPointCollection",
      "itemCollection",
      "webCategoryCollection",
    ]),

    formTitle() {
      return this.modalData.editedIndex === -1 ? "New Website" : "Edit Website";
    },
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    dragOptionsChips() {
      return {
        animation: 200,
        group: "group",
        disabled: false,
        ghostClass: "ghost",
        sort: true,
      };
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.formModel = value.editedItem;
        // if (this.formModel.country.id) {
        //   this.formModel.country = this.formModel.country.id;
        // }
        this.setTranslatedAttributes();
      },
    },
  },
  methods: {
    ...mapActions([
      "fetchPriceList",
      "fetchSalesPoint",
      "fetchItem",
      "fetchWebCategory",
    ]),
    handleCloseModalForm() {
      this.$emit("closeModalForm");
    },
    handleSaveModalForm() {
      this.$refs.form.validate();
      if (this.formValid) {
        this.$emit("saveModalForm", Object.assign({}, this.formModel));
      }
    },
    setTranslatedAttributes(newItem = false) {
      if (TRANSLATED_ATRIBUTES.length > 0) {
        if (newItem) {
          this.languages.forEach((language) => {
            TRANSLATED_ATRIBUTES.forEach((attribute) => {
              this.formModel.translations[language.lang] = {};
              this.formModel.translations[language.lang][attribute] = "";
            });
          });
        } else {
          this.languages.forEach((language) => {
            TRANSLATED_ATRIBUTES.forEach((attribute) => {
              if (!this.formModel.translations[language.lang]) {
                this.formModel.translations[language.lang] = {};
                this.formModel.translations[language.lang][attribute] = "";
              }
            });
          });
        }
      }
    },
    handleNew() {
      this.formModel.name = "";
      this.setTranslatedAttributes(true);
    },
    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name == "companySettings.site." + action
      );
    },

    toggle: function (todo) {
      todo.done = !todo.done;
    },

    move: function (value) {
      this.dragged = {
        from: parseInt(value.from.id),
        to: parseInt(value.to.id),
        newIndex: value.draggedContext.futureIndex,
      };
    },

    change: function (value) {
      if (value.removed) {
        // insert
        this.formModel.custom_fields.selected_products.splice(
          this.dragged.to + this.dragged.newIndex,
          0,
          this.formModel.custom_fields.selected_products[this.dragged.from]
        );
        // delete
        if (this.dragged.from < this.dragged.to)
          // LTR
          this.formModel.custom_fields.selected_products.splice(
            this.dragged.from,
            1
          );
        // RTL
        else
          this.formModel.custom_fields.selected_products.splice(
            this.dragged.from + 1,
            1
          );
      }
    },
  },
  async mounted() {
    this.fetchPriceList();
    this.fetchSalesPoint();
    this.fetchWebCategory();
    this.fetchItem();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
